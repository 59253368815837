.app {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    justify-content: space-between;
    column-gap: 200px;
    margin-bottom: 60px;
  }

  .left_section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .top__title {
      font-size: 25px;
    }
  }

  .top__button {
    min-height: 70px;
    max-height: 100px;
    font-size: 17px;
  }

  .top__newTaskButton {
    max-width: 243px;
    margin-bottom: 30px;
    font-size: 19px;
  }

  .top__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
}
