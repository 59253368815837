.no_tasks {
  font-size: 26px;
}

.task_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: azure;
  padding: 20px;
  margin-bottom: 10px;

  .task_item_change_button {
    font-size: 16px;
    padding: 5px;
  }
}
