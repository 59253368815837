.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  width: 300px;

  .form__title {
    display: flex;
    align-items: center;
    flex-direction: column;

    label {
      font-size: 30px;
      margin-bottom: 16px;
    }

    input {
      font-size: 20px;
    }
  }

  .form__text {
    display: flex;
    align-items: center;
    flex-direction: column;

    label {
      font-size: 30px;
      margin-bottom: 16px;
    }

    textarea {
      font-size: 20px;
    }
  }

  .form__checkbox {
    font-size: 20px;
    display: flex;
    align-items: center;

    input {
      width: 30px;
      height: 30px;
    }
  }
  .form__button {
    font-size: 17px;
  }
}
